
@import "../../variables.less";

.head {
	line-height: 32px;
}

.attach {
	width: 260px;
	float: right;
	color: #FFF;

	> div {
		width: 50%;
		padding: 0 8px;
		display: inline-block;
	}
}

.summary {
	color: #FFF;
	margin: @bigSpace 0;

	> div {
		width: 50%;
		display: inline-block;
		text-align: center;
		vertical-align: top;

		&:first-child {
			font-size: @fontSizeA;
			line-height: 55px;
		}

		&:last-child {
			font-size: @fontSizeB;
		}
	}
}

.chartTitle {
	font-size: @fontSizeC;
	margin-left: @middleSpace;
	border-bottom: 1px solid #FFF;
	color: white;
}

.departments {
	width: 50%;
	padding: @smallSpace;
	display: inline-block;
	color: white;
	font-size: @fontSizeD;

	> div {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		display: flex;
		padding: @smallSpace;

		> div {
			width: 50%;

			&:first-child {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	&::after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		cursor: pointer;
	}
}

.departmentBox {
	margin-top: @bigSpace;
	flex-grow: 1;
	overflow: auto;
	padding: 0 @bigSpace;
}

.childrenCharts {
	width: 50%;
	display: inline-block;
	height: 100%;
	padding: 0 @smallSpace;
	float: left;
}
